import { toggleModal, MODAL_CLASS, slideBack } from './lib';
// import Toast from "../lib/toast";
// import SelectSlide from './slides/select';
import NicknameSlide from './slides/nickname';
import AgreementSlide from './slides/agreement';
// import noty from "../lib/ui/noty";
import API from '../lib/api';
import toastr from 'toastr';
import appVersion from '../lib/utils/appVersion';

onload(() => {
  // X button
  const closeBtn = document.querySelector(`.${MODAL_CLASS} .close-modal`);
  closeBtn?.addEventListener('click', e => {
    if (closeBtn.dataset.logOut) {
      Swal.fire({
        title: '로그아웃',
        text: "로그아웃하시겠습니까?",
        showCancelButton: true,
        // showDenyButton: true,
        confirmButtonText: '로그아웃',
        cancelButtonText: '취소',
        // denyButtonText: '취소'
      }).then((result) => {
        if (result.isConfirmed) {
          API.sessions.signOut
            .call({
              redirect_url: window.location.href
            })
            .then(res => {
              toggleModal(false);
              if (res.data?.redirect_url) redirectTo(res.data?.redirect_url);
            })
            .catch(err => {
              toastr.error(err.message);
            });
        }
      });
    } else toggleModal(false);
  });

  // Back button
  document.querySelector(`.${MODAL_CLASS} .back-button`)?.addEventListener('click', e => {
    slideBack();
  });

  switch(appData().session) {
    case 'nickname':
      toggleModal(true, NicknameSlide, { redirectUrl: window.location.href, hideNav: true, user: { email: appData().email } });
      break;
      
    case 'agreement':
      closeBtn.dataset.logOut = true;
      toggleModal(true, AgreementSlide, { redirectUrl: window.location.href, user: { email: appData().email } });
      break;
  }

  // console.log(appVersion().atLeast("1.2.4"));
}, {});
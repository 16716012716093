import API from '@/lib/api';
import { status, slideTo } from '../../lib';
import Slide from '../base';
import NaverSlide from './naver';
import KakaoSlide from './kakao';
import GoogleSlide from './google';

export default class SnsSlide {
  static of(type) {
    switch(type) {
      case 'naver':
        return NaverSlide;
      case 'kakao':
        return KakaoSlide;
      case 'google':
        return GoogleSlide;
    }
  }
}
import API from '@/lib/api';
import { status, slideTo, slideBack } from '../lib';
import Slide from './base';
import NicknameSlide from './nickname';

export default class AgreementSlide extends Slide {
  static slideName = 'agreement';
  static navType = 'close';

  static init = () => {
    this.titles.forEach((e, i) => {
      e.addEventListener('click', evt => {
        this.showContent(i);
        evt.stopPropagation();
      });
    });

    this.slide.addEventListener('click', e => {
      this.showContent();
    });
  }

  static update = () => {
    this.showContent();
  }

  static refresh = () => {
    this.placeholder.value = status.user.email || "";
  }

  static click = () => {
    if (status.isRequesting) return;
    status.isRequesting = true;
    
    API.sessions.agree
      .call(status.user)
      .then(res => {
        status.user = res.data || {};

        switch(res.code) {
          case 1:
            slideTo(NicknameSlide);
            break;
        }
      }).catch(error => {
        switch(error.code) {
          default:
            this.onError(error.message);
        }
      }).finally(() => {
        status.isRequesting = false;
      });
  }

  // 약관 관련 UI
  static get titles() {
    return this.slide.querySelectorAll('.agree-underline');
  }
  static get contents() {
    return this.slide.querySelectorAll('.wrap__agree-content');
  }

  static showContent(index = undefined) {
    this.titles.forEach((e, i) => {
      if (i === index) e.classList.add('clicked');
      else e.classList.remove('clicked');
    });
    this.contents.forEach((e, i) => {
      if (i === index) e.classList.add('active');
      else e.classList.remove('active');
    });
  }
}